@use 'styles/abstracts' as *;

.container {
  margin-top: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25rem;
  @media only screen and (max-width: 1600px) {
    padding: 0 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 0 7rem;
  }
  @include breakpoint(tab-port) {
    margin-top: 3.75rem;
    padding: 0 1rem;
  }
  .wrap {
    width: 100%;
    h3 {
      margin-bottom: 1rem;
    }
    @include breakpoint(tab-land) {
      h3 {
        margin-bottom: 0.8125rem;
      }
    }
    @include breakpoint(phone) {
      width: calc(100vw - 2rem);
    }
    .form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      .infoWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 0.75rem;
        gap: 1.5rem 0.75rem;
        input::placeholder,
        textarea::placeholder {
          font-family: $montserrat;
          font-size: 1rem;
        }
        @include breakpoint(phone) {
          grid-template-columns: repeat(1, 1fr);
        }
        .inputWrapper {
          display: flex;
          input {
            width: 100%;
            padding-right: 1.875rem;
          }
          .uploadFile {
            display: none;
          }
          .labelUpload {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            height: 100%;
            box-shadow: 0.3125rem 0.3125rem 0.625rem #00000014;
            color: $col-primary-700;
            cursor: pointer;
          }
        }
        .datePicker {
          padding-right: 10px;
        }
      }
      input:disabled {
        color: $col-gray-300;
        background-color: white;
      }
      select {
        color: $col-primary-700;
        grid-column: 1;
        margin-bottom: 1.875rem;
        height: 2rem;
        padding: 0 10px;
        border: none;
        box-shadow: 5px 5px 10px #00000014;
        background-color: white;
        font-size: 18px;
        outline: none;
      }
      select:disabled {
        color: $col-gray-300;
      }
      .positionWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include breakpoint(phone) {
          grid-template-columns: repeat(1, 1fr);
        }
        h6 {
          grid-column: 1;
          margin-bottom: 0.75rem;
        }
        p {
          grid-column: 1;
          margin-bottom: 0.5rem;
        }
        textarea {
          grid-column: 1 / 4;
          width: 100%;
          overflow-x: hidden;
          min-height: 10rem;
          background-color: $col-gray-200;
          border: none;
          padding: 0.75rem;
          resize: none;
          color: #2b3939;
          font-family: $montserrat;
          font-weight: lighter;
          font-size: 16px;
          outline: none;
        }
        .hidden {
          display: none;
          color: $col-primary-700;
        }
      }
      .internshipWrapper {
        display: flex;
        flex-direction: column;
        h6 {
          margin-bottom: 0.75rem;
        }
        .switchWrapper {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 1.375rem;
          .switch {
            :global(.Mui-checked) {
              color: $col-white !important;
            }
            .grey {
              :global(.MuiSwitch-track) {
                height: 0.8rem;
                background-color: #96acab !important;
              }
            }
            .blue {
              :global(.MuiSwitch-track) {
                height: 0.8rem;
                background-color: $col-primary-600 !important;
              }
            }
            display: flex;
            align-items: center;
            label {
              font-size: 0.75rem;
            }
          }
        }
        .collaborationTypeWrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0.75rem;
          select {
            @include breakpoint(phone) {
              margin-bottom: 0;
            }
          }
          @include breakpoint(phone) {
            display: flex;
            flex-flow: column;
          }
          input {
            outline: none;
            grid-column: 2;
          }
        }
      }
      .privacyWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        .checkWrapper {
          display: flex;
          gap: 0.75rem;
          align-content: center;
          input[type='checkbox'] {
            accent-color: $col-primary-600;
          }
          a {
            color: $col-primary-600;
          }
          input {
            visibility: visible;
          }
        }
      }
    }
  }
  .sendButton {
    align-self: flex-end;
    padding: 0.75rem;
    box-shadow: 5px 5px 10px #00000014;
    color: $col-primary-700;
  }
  .sendButton:disabled {
    color: $col-gray-300;
    box-shadow: none;
  }
}
