@use 'styles/abstracts' as *;

.container {
  width: 100%;
  padding-top: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $col-white;
  @include breakpoint(tab-port) {
    padding: 0 1rem;
    padding-top: 2rem;
  }
  .content {
    width: 100%;
    margin-bottom: 6.25rem;
    @include breakpoint(tab-land) {
      margin-bottom: 2.75rem;
    }
    .headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 2.5rem 0;
      a {
        color: $col-primary-500;
        text-decoration: underline;
      }
      @include breakpoint(tab-port) {
        flex-direction: column;
        gap: 1.25rem;
        margin: 1rem 0;
      }
      .socialsOnMobile {
        display: none;
        @include breakpoint(tab-port) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .mobileLinkWrap {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 1rem;
        }
      }
      .headerLinks {
        display: flex;
        gap: 1rem;
        @include breakpoint(tab-port) {
          flex-direction: column;
          gap: 1.25rem;
        }
        a {
          font-size: 1rem;
          color: $col-primary-500;
        }
      }
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      @include breakpoint(tab-port) {
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 2.125rem;
      }
      .blockOne {
        display: flex;
        flex-direction: column;
        gap: 2.125rem;
        padding-left: 1rem;
        border-left: 2px solid $col-gray-300;
      }
      .blockTwo {
        display: flex;
        flex-direction: column;
        gap: 2.125rem;
        padding-left: 1rem;
        border-left: 2px solid $col-gray-300;
      }
      .blockThree {
        display: flex;
        flex-direction: column;
        gap: 2.125rem;
        padding-left: 1rem;
        border-left: 2px solid $col-gray-300;
      }
      .blockFour {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding-left: 1rem;
        border-left: 2px solid $col-gray-300;
        @include breakpoint(tab-port) {
          display: none;
        }
      }
    }
  }
}
