@use 'styles/abstracts' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.25rem;
  padding: 0 25rem;
  background-color: $col-white;
  @media only screen and (max-width: 1600px) {
    padding: 0 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 0 7rem;
  }
  @include breakpoint(tab-port) {
    padding: 0 1rem;
    margin-top: 3.75rem;
  }
  .content {
    padding-top: 6.25rem;
    width: 100%;
    h3 {
      color: $col-primary-600;
      margin-bottom: 2.1875rem;
    }
    @include breakpoint(tab-land) {
      h3 {
        margin-bottom: 1.4375rem;
      }
    }
    @include breakpoint(phone) {
      padding-top: 3rem;
    }
    .carousel {
      background-color: $col-white;
      position: relative;
      :global(.react-multi-carousel-dot--active button) {
        width: 0.5rem;
        height: 0.5rem;
        background-color: $col-primary-500 !important;
        border: $col-primary-500;
      }

      :global(.react-multi-carousel-dot button) {
        color: $col-gray-200;
        opacity: 1;
        background-color: $col-gray-200;
        width: 0.5rem;
        height: 0.5rem;
        border: $col-gray-200;
      }

      :global(.react-multi-carousel-item) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: $col-white;
        .carouselImgContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.8125rem;
          height: 7.8125rem;
          background-color: $col-gray-200;
          margin-bottom: 2rem;
          @include breakpoint(tab-port) {
            width: 6.25rem;
            height: 6.25rem;
          }
          @include breakpoint(phone) {
            width: 5rem;
            height: 5rem;
          }
          .carouselImg {
            padding: 0.5rem;
            width: 100%;
            height: 100%;
            object-fit: contain;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          }
        }
      }
      .overlay {
        background: -moz-linear-gradient(
          left,
          rgba(255, 0, 0, 0) 72%,
          rgb(255, 255, 255) 100%
        ); /* FF3.6+ */
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(72%, rgba(255, 0, 0, 0)),
          rgb(255, 255, 255) 100%
        ); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(
          left,
          rgba(255, 0, 0, 0) 72%,
          rgb(255, 255, 255) 100%
        ); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(
          left,
          rgba(255, 0, 0, 0) 72%,
          rgb(255, 255, 255) 100%
        ); /* Opera 11.10+ */
        background: -ms-linear-gradient(
          left,
          rgba(255, 0, 0, 0) 72%,
          rgb(255, 255, 255) 100%
        ); /* IE10+ */
        background: linear-gradient(
          to right,
          rgba(255, 0, 0, 0) 72%,
          rgb(255, 255, 255) 100%
        ); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        @include breakpoint(phone) {
          display: none;
        }
      }
    }
  }
}
