*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
}

