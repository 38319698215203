@use 'styles/abstracts' as *;

.container {
  background-image: url('../../../assets/images/bg_hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 8.75rem 45%;
  padding-bottom: 6.25rem;
  @include breakpoint(tab-land) {
    grid-template-columns: 3rem 90%;
    padding-bottom: 3rem;
  }
  @include breakpoint(phone) {
    background-image: url('../../../assets/images/bg_hero_mobile.png');
    aspect-ratio: 375 /811;
    grid-template-columns: 1fr;
    padding: 0 1rem 2rem 1rem;
  }
  .wrapper {
    grid-column: 2;
    align-self: flex-end;
    h5 {
      font-weight: normal;
    }
  }
}
