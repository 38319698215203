@use 'styles/abstracts' as *;

.container {
  height: 6.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8.75rem;
  z-index: 50;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: fixed;
  top: 0;
  justify-content: space-between;
  @include breakpoint(tab-port) {
    height: 5rem;
    padding: 1rem;
  }
}

