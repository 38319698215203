@use 'styles/abstracts' as *;

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
.bodyContainer {
  height: 100dvh;
  width: 100dvw;
  .routesContainer {
    height: calc(100dvh);
    overflow: auto;
    background-color: $col-gray-100;
    .footer {
      background-color: $col-white;
      padding: 0 25rem;
      @media only screen and (max-width: 1600px) {
        padding: 0 13rem;
      }
      @include breakpoint(tab-land) {
        padding: 0 7rem;
      }
      @include breakpoint(tab-port) {
        padding: 0 1rem;
      }
    }
  }
}
