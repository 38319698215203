@use 'styles/abstracts' as *;

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
.bodyContainer {
  height: 100dvh;
  width: 100dvw;
  padding: 2rem 15% 0 15%;
  overflow: auto;
  @include breakpoint(tab-port) {
    padding: 2rem 5% 0 5%;
  }
  .privacyContainer {
    height: 100%;
    h1 {
      margin-bottom: 2.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      @include breakpoint(tab-port) {
        margin-bottom: 1.5rem;
      }
    }
    .subtitle {
      margin-bottom: 0.9375rem;
    }
    p {
      margin-bottom: 0.9375rem;
    }
    ul {
      li {
        list-style: none;
      }
    }
    a {
      text-decoration: none;
      color: $col-primary-600;
    }
    .footerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
