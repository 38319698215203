@use '../abstracts/' as *;

body {
  font-family: $montserrat;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  @include breakpoint(tab-port) {
    font-size: 1rem;
  }
  @include breakpoint(phone) {
    font-size: 0.8125rem;
  }
  b {
    font-weight: bold;
  }
}

h1 {
  font-family: $vietnam;
  font-size: 3.25rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 3rem;
  }
  @include breakpoint(phone) {
    font-size: 1.875rem;
  }
}

h2 {
  font-family: $vietnam;
  font-size: 2.75rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 2.5rem;
  }
  @include breakpoint(tab-port) {
    font-size: 2.125rem;
  }
  @include breakpoint(phone) {
    font-size: 2.125rem;
  }
}

h3 {
  font-family: $vietnam;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  font-family: $vietnam;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  font-family: $vietnam;
  font-size: 1.3125rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 1.125rem;
  }
  @include breakpoint(tab-port) {
    font-size: 1.125rem;
  }
  @include breakpoint(phone) {
    font-size: 1rem;
  }
  b {
    color: $col-gray-600;
  }
}
h6 {
  font-family: $montserrat;
  font-size: 1.3125rem;
  text-transform: capitalize;
  @include breakpoint(tab-port) {
    font-size: 1.125rem;
  }
}

p {
  font-family: $montserrat;
  font-size: 1.125rem;
  font-weight: 300;
  @include breakpoint(tab-land) {
    font-size: 1.125rem;
  }
  @include breakpoint(tab-port) {
    font-size: 1.125rem;
  }
  @include breakpoint(phone) {
    font-size: 1.125rem;
  }
  b {
    font-weight: 600;
  }
}

.blue {
  color: $col-primary-600;
}

.white {
  color: $col-white;
}
.black {
  color: $col-black;
}

