@use 'styles/abstracts' as *;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  padding: 1rem 25rem;
  background-color: #707070;
  @media only screen and (max-width: 1600px) {
    padding: 1rem 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 1rem 7rem;
  }
  @include breakpoint(tab-port) {
    padding: 1rem;
  }
  @include breakpoint(phone) {
    display: flex;
    flex-flow: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }
  .explication {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 1.875rem;
    @include breakpoint(phone) {
      align-items: flex-start;
      gap: 1.25rem;
    }
  }
  div {
    display: flex;
    flex-flow: column;
    width: 65%;
    @include breakpoint(phone) {
      width: 100%;
    }
  }
  button {
    width: 6.25rem;
    height: 2rem;
    border-radius: 0.125rem;
    background-color: $col-primary-300;
    border: none;
    @include breakpoint(phone) {
      align-self: flex-start;
      margin-left: 2.8rem;
    }
  }
}
