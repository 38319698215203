@use 'styles/abstracts' as *;

.container {
  margin-top: 5rem;
  @include breakpoint(tab-land) {
    margin-top: 3.75rem;
  }
  .containerOne {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 1rem;
    @include breakpoint(tab-port) {
      flex-direction: column;
      padding: 0 1rem;
    }
    @include breakpoint(phone) {
      align-items: flex-start;
    }
    .titleContainer {
      display: flex;
      flex-direction: column;
      text-align: end;
      gap: 2rem;
      @include breakpoint(tab-port) {
        gap: 1rem;
        text-align: start;
      }
    }
    .parContainer {
      max-width: 30%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include breakpoint(tab-land) {
        max-width: 40%;
      }
      @include breakpoint(tab-port) {
        max-width: 100%;
        gap: 1rem;
      }
    }
  }
  .containerTwo {
    background-image: url('../../../assets/images/bg_mountains.png');
    background-size: contain;
    padding-top: 32.34375%; /* 16:9 aspect ratio (9 / 16 * 100%)  */
    @include breakpoint(tab-port) {
      background-image: url('../../../assets/images/bg_mountains_mobile.png');
      padding-top: 85.6%; /* 16:9 aspect ratio (9 / 16 * 100%)  */
    }
  }
}

