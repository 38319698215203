@use 'styles/abstracts' as *;

.youtube:hover {
  cursor: pointer;
  .youtubePath {
    fill: #d84939;
  }
}

.facebook:hover {
  cursor: pointer;
  .facebookPath {
    fill: #4b6ea8;
  }
}
.linkedin:hover {
  cursor: pointer;
  .linkedinPath {
    fill: #0088c2;
  }
}

.arrowBackward {
  cursor: pointer;
}
