@use 'styles/abstracts' as *;

.button {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  cursor: pointer;
  .flag {
    width: 1.5rem;
    height: 1rem;
  }
}
.container {
  div {
    button {
      p {
        display: inline-block;
        @include breakpoint(phone) {
          display: none;
        }
      }
    }
  }
}
.langButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 1.5rem;
      height: 1rem;
    }
  }
}
input {
  visibility: hidden;
}
label {
  cursor: pointer;
}
