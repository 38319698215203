$col-alert-dark: #96092b;
$col-alert-light: #db0b3d;
$col-gray-100: #f7f9f9;
$col-gray-200: #e8efee;
$col-gray-300: #c8dad8;
$col-gray-400: #a7c0bf;
$col-gray-500: #96acab;
$col-gray-600: #5c7272;
$col-gray-700: #2b3939;
$col-gray-800: #1a2526;
$col-gray-900: #000a0c;
$col-gray-dark: #696765;
$col-pink: #ffc6d4;
$col-primary-100: #c0efef;
$col-primary-200: #8fe3ec;
$col-primary-300: #45d1e1;
$col-primary-400: #3cc0db;
$col-primary-500: #16a3cf;
$col-primary-600: #008fc1;
$col-primary-700: #00576b;
$col-primary-800: #002d38;
$col-primary-900: #011e23;
$col-secondary-100: #ceffec;
$col-secondary-200: #8befc9;
$col-secondary-300: #5aecb5;
$col-secondary-400: #0ee598;
$col-secondary-500: #00d584;
$col-secondary-600: #00b26e;
$col-secondary-700: #008753;
$col-secondary-800: #005736;
$col-secondary-900: #003923;
$col-warning1-dark: #b25900;
$col-warning1-light: #f3881d;
$col-warning2-dark: #b6a900;
$col-warning2-light: #eded15;
$col-white: #ffffff;
$col-black: #000000;
$col-eagle-black: #000a0c;

