@use 'styles/abstracts' as *;

.container {
  margin-top: 6.25rem;
  display: flex;
  justify-content: center;
  padding: 0 25rem;
  @media only screen and (max-width: 1600px) {
    padding: 0 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 0 7rem;
  }
  @include breakpoint(tab-port) {
    padding: 0 1rem;
    margin-top: 3.75rem;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h3 {
      margin-bottom: 1rem;
    }
    @include breakpoint(tab-land) {
      h3 {
        margin-bottom: 0.8125rem;
      }
    }
    .cardsContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.375rem;
      margin-top: 1.5rem;
      @include breakpoint(tab-land) {
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        margin-top: 1rem;
      }
      @include breakpoint(tab-port) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
      }
      @include breakpoint(phone) {
        grid-template-columns: repeat(1, 1fr);
      }
      .card {
        height: 100%;
        min-height: 12rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        padding: 0.875rem 1rem 3rem 1rem;
        svg {
          width: 3.75rem;
          height: 3.75rem;
        }
      }
    }
  }
}
