@use 'styles/abstracts' as *;

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
.bodyContainer {
  height: 100dvh;
  width: 100dvw;
  overflow: auto;
  .disclaimerContainer {
    padding: 2rem 15% 0 15%;
    height: 100%;
    @include breakpoint(tab-port) {
      padding: 2rem 5% 0 5%;
    }
    h1 {
      margin-bottom: 2.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      @include breakpoint(tab-port) {
        margin-bottom: 1.5rem;
      }
    }
    .subtitle {
      margin-bottom: 0.9375rem;
    }
    p {
      margin-bottom: 0.9375rem;
    }
    footer {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 0;
      }
      .wrapperOne {
        display: flex;
        justify-content: space-between;
        margin-top: 5rem;
        margin-bottom: 2.5rem;
        @include breakpoint(tab-port) {
          margin-top: 3rem;
          margin-bottom: 2.5rem;
          flex-direction: column;
          gap: 3rem;
          justify-content: center;
          align-items: center;
        }
        .linksContainer {
          display: flex;
          gap: 1rem;
          @include breakpoint(tab-port) {
            gap: 0.5rem;
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
    .footerContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
