@use '../abstracts/' as *;

.inputError {
  border: 1px solid $col-alert-light !important;
}
.inputText {
  height: 2.25rem;
  visibility: visible;
  background-color: $col-gray-200;
  border: none;
  padding-left: 0.75rem;
  color: #2b3939;
  font-family: $montserrat;
  font-weight: lighter;
  font-size: 16px;
  outline: none;
}
