@mixin breakpoint($point) {
  @if $point == big-desktop {
    @media only screen and (min-width: 112.5em) {
      //1800px
      @content;
    }
  }

  @if $point == tab-land {
    @media only screen and (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if $point == tab-port {
    @media only screen and (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $point == phone {
    @media only screen and (max-width: 37.5em) {
      //600px
      @content;
    }
  }
}

