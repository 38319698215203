@use 'styles/abstracts' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.25rem;
  padding: 0 25rem;
  @media only screen and (max-width: 1600px) {
    padding: 0 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 0 7rem;
  }
  @include breakpoint(tab-port) {
    margin-top: 3.75rem;
    padding: 0 1rem;
  }
  .content {
    h3 {
      margin-bottom: 1rem;
    }
    @include breakpoint(tab-land) {
      h3 {
        margin-bottom: 0.8125rem;
      }
    }
    .gridContainer {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 1.5rem;
      @include breakpoint(tab-land) {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
      }
      .blockOne {
        grid-column: 1;
        grid-row: 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .blockTwo {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        grid-column: 2;
        grid-row: 1/2;
        @include breakpoint(tab-land) {
          gap: 2rem;
        }
      }
      .blockThree {
        margin-top: 2rem;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1 / 3;
        grid-row: 2;
        align-items: start;
        @include breakpoint(tab-port) {
          grid-template-columns: repeat(1, 1fr);
        }
        .card {
          border: 1px solid $col-primary-600;
          padding: 1rem;
        }
      }
    }
  }
  a {
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    color: $col-primary-600;
  }
}
