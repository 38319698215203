@use 'styles/abstracts' as *;

.container {
  width: 100dvw;
  padding: 7.5rem 25rem 5rem 25rem;
  @media only screen and (max-width: 1600px) {
    padding: 7.5rem 13rem 5rem 13rem;
  }
  @include breakpoint(tab-land) {
    padding: 3rem 7rem;
  }
  @include breakpoint(tab-port) {
    margin-top: 3.75rem;
    padding: 3rem 1rem;
  }
  .content {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid $col-gray-400;
    gap: 2.5rem;
    @include breakpoint(tab-land) {
      flex-flow: column;
    }
    .image {
      background-image: url('../../../assets/images/map.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 40.47rem;
      @include breakpoint(phone) {
        background-image: url('../../../assets/images/map_mobile.png');
        height: 17.09rem;
        width: 100%;
      }
    }
    .links {
      display: flex;
      flex-flow: column;
      gap: 2.5rem;
      @include breakpoint(tab-land) {
        flex-flow: row;
        justify-content: space-around;
        gap: 0;
      }
      @include breakpoint(phone) {
        justify-content: space-between;
      }
      .references {
        display: flex;
        flex-flow: column;
        gap: 2.5rem;
        justify-content: space-between;
        @include breakpoint(tab-port) {
          gap: 1.5rem;
        }
        .countryReference {
          display: flex;
          flex-flow: column;
          gap: 0.5rem;
          .title {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 0.5rem;
            a {
              color: $col-primary-500;
            }
            h3 {
              color: $col-primary-500;
            }
          }
          .cities {
            display: flex;
            flex-flow: column;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}
